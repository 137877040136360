import { Box, Flex } from 'grid-styled'
import { graphql, Link } from 'gatsby'
import * as React from 'react'
import Layout from '../components/Layout'
import { Helmet } from 'react-helmet'
import Map from '../components/Map/Map'

import CTA from '../components/CTA'
import Header from '../components/Header'
import FAQs from '../components/FAQs'
import Contact from '../components/Contact'
import Hero from '../components/Hero'
import Button from '../components/Button'
import styled from 'styled-components'
import Card from '../components/Card'
import { yPad } from '../utils/theme'

const Content = styled.div`
  max-width: 750px;
  width: 90%;
  margin: 80px auto 50px;

  h2 {
    font-weight: 400;
    margin-bottom: 1em;
  }

  span {
    color: ${(props) => props.theme.colors.primary};
    font-size: 20px;
    display: inline-block;
    margin-bottom: 1em;
  }
`

const Details = styled.section`
  background-color: #f9f8f7;
  padding: 100px;
  h2 {
    text-align: center;
    margin-bottom: 3rem;
    i {
      color: ${(props) => props.theme.colors.primary};
      font-style: normal;
    }
  }

  section {
    display: flex;
    justify-content: space-around;

    @media (max-width: 550px) {
      flex-direction: column;
      align-items: center;
    }
  }

  @media (max-width: 1000px) {
    padding: 100px 25px;
  }

  @media (max-width: 640px) {
    flex-direction: column;
    align-items: center;
    div {
      width: 80%;
      margin-bottom: 40px;
    }
  }
  @media (max-width: 500px) {
    div {
      justify-content: center;
      align-items: center;
    }
  }

  strong {
    font-weight: 500;
    margin-right: 1.5rem;
  }

  p {
    margin: 0;
  }
`

const ContactCards = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  ${yPad};
  padding-bottom: 0 !important;

  ${Box} {
    max-width: 450px;
  }

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 75%;
    background-color: #f9f8f7;

    @media (min-width: 940px) {
      height: 155px;
    }
  }

  @media (max-width: 639px) {
    flex-direction: column;
    ${Box} {
      margin: 0 auto;
    }
  }
`

const openChat = () => drift.api.sidebar.open()

export const ContactDetails = ({ title, children }) => (
  <Details>
    {title}
    <section>
      <div>
        <h4>Contact Details</h4>
        <p>
          <strong>Phone</strong> 1300 284 531
        </p>
        <p>
          <strong>Email&nbsp;</strong> sales@justpatios.com.au
        </p>
        <p>
          <strong>Fax&emsp;&nbsp;</strong> (07) 5428 6896
        </p>
      </div>
      <div>
        <h4>Address</h4>
        <p>
          <strong>Office &amp; Showroom</strong>
          <br />
          412-420 Old Gympie Rd <br />
          Caboolture QLD 4510
        </p>
        <p style={{ marginTop: 10 }}>
          <strong>Postal</strong>
          <br />
          PO Box 806, <br />
          Morayfield, QLD 4506
        </p>
      </div>
      <div>
        <h4>Office Hours</h4>
        <p>
          <strong>Mon - Thur</strong> 8:30am - 5:00pm
        </p>
        <p>
          <strong>Friday&emsp;&emsp;&nbsp;</strong> 8:30am - 4:00pm
        </p>
        <p>
          <strong>Weekend&nbsp;&nbsp;&nbsp;</strong> Closed
        </p>
      </div>
    </section>
    {children}
  </Details>
)

export default ({ data }) => (
  <Layout>
    <Helmet>
      <title>
        Contact Brisbane & Sunshine Coast’s Trusted Builders | Just Patios
      </title>
      <meta
        name="description"
        content="Just Patios provides high-quality patios, carports & more in the Sunshine Coast and Brisbane. You can count on us for experience, quality and customer service. Get in touch with us today to request a free quote & design."
      />
    </Helmet>

    <Header>
      <h1>Contact us today</h1>
    </Header>

    <ContactCards>
      <Box mx={3}>
        <Link to="/contact-us#form">
          <Card
            shadow={true}
            border={true}
            color="primary"
            title="Send an email"
            content="Have a question, or want to find out what we your patio, carport, roof, or room enclosure options are? Email us at any time and we'll get back to you shortly."
          >
            {/* <Envelope /> */}
          </Card>
        </Link>
      </Box>
      <Box mx={3} onClick={openChat} style={{ cursor: 'pointer' }}>
        <a href="tel:1300284531">
          <Card
            shadow={true}
            border={true}
            color="tertiary"
            title="Give us a call"
            content="Too eager to wait for email? Good news, we can chat live on the phone. Let's work through your questions in real time. We love to help in any way we can."
          >
            {/* <Chat /> */}
          </Card>
        </a>
      </Box>
    </ContactCards>

    <ContactDetails />

    <Map style={{ marginTop: 0 }} />

    <Contact
      id="form"
      style={{ paddingTop: '8rem', textAlign: 'left' }}
      noBorder={true}
    />
  </Layout>
)

export const query = graphql`
  query ContactPageQuery {
    womanWaterfall: file(relativePath: { eq: "track.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
